@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.loadingBody {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 60px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    @include iphone {
        min-height: calc(100vh - 45px);
    }
}