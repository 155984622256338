@import './styles/mixins.scss';
@import './styles/variables.scss';

.mainAppBody {
    background-color: black;
    background-image:
    radial-gradient(
      circle at bottom right,
      rgb(60, 10, 10), black
    );
    .appHeaderPosition {
        z-index: 1;
        width: 100%;
        height: 60px;
        position: sticky;
        top: 0;
        @include iphone {
            height: 45px;
        }
    }
}
