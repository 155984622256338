@import '../styles/mixins.scss';
@import '../styles/variables.scss';

.mainHeaderBody {
    width: 100%;
    height: 100%;
    background-color: black;
    color: lightblue;
    display: flex;
    align-items: center;
    // border-bottom: solid 1px lightblue;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    box-sizing: border-box;
    padding: 0 40px;
    font-size: 15px;
    @include iphone {
        padding: 0 8px;
    }
    .textBox {
        display: flex;
        align-items: flex-end;
        border: solid 1px lightblue;
        border-radius: 5px;
        padding: 0 4px;
        background-color: rgb(0, 0, 29);
        box-sizing: border-box;
        @include iphone {
            align-items: flex-start;
            flex-direction: column;
        }
        .title {
            font-weight: bold;
            font-size: 24px;
            cursor: pointer;
            @include iphone {
                font-size: 16px;
            }
        }
        .description {
            margin: 0 10px;
            @include iphone {
                font-size: 12px;
                margin: 0;
            }
        }
    }
    .whatBox {
        margin-left: auto;
        cursor: pointer;
        border: solid 1px lightblue;
        border-radius: 5px;
        padding: 1px 4px;
        background-color: rgb(0, 0, 29);
    }
}